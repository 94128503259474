import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Welcome from '../components/Welcome';
import LearnMore from '../components/LearnMore';
import Testimonials from '../components/Testimonials';

export default function Home() {
  return (
    <Layout>
      <DarkBackgroundStyles aria-hidden="true" />
      <Hero />
      <Welcome />
      <Testimonials />
      <LearnMore />
    </Layout>
  );
}

const DarkBackgroundStyles = styled.div`
  background-color: #2a2721;
  height: 170px;
`;
